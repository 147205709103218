import ROUTES from 'libs/constants/routes';

export const isAutoEfectivoLanding = window.location.pathname.includes(
  ROUTES.LANDING_AE,
);

export const isBcpBexExperience = (channel: string | null) =>
  Boolean(channel && channel.includes('bcpbex-experiencia'));

export const isAdvisorPilotLanding = (channel: string | null) =>
  Boolean(channel && channel.includes('callcenter'));

export const isPilotChannelLanding = (channel: string | null) =>
  isBcpBexExperience(channel) || isAdvisorPilotLanding(channel);

export const getChannel = (channel: string | null) =>
  isBcpBexExperience(channel) && channel ? channel.split('-')[0] : channel;

export const getChannelName = () =>
  window.location.pathname.split('/').at(2) || '';
